import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "./index.scss";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Marine Contractors Suffolk | Marine Contractors Ltd"
      keywords={[`marine contractors`, `suffolk`, `east anglia`]}
    />
    <h1 className="headline" itemprop="description">
      Leading marine contractors based in Suffolk, East Anglia.
    </h1>
    <div className="map" />
    <div
      className="contact"
      itemscope=""
      itemtype="http://schema.org/LocalBusiness"
    >
      <span itemprop="name">Marine Contractors Ltd</span>
      <br />
      <address
        itemprop="address"
        itemscope=""
        itemtype="http://schema.org/PostalAddress"
      >
        <span itemprop="streetAddress">Riverside Business Centre</span>
        <br />
        <span itemprop="streetAddress">Riverside Road</span>
        <br />
        <span itemprop="addressLocality">Lowestoft</span>
        <br />
        <span itemprop="addressRegion">Suffolk</span>
        <span itemprop="postalCode">NR33 OTQ</span> <br />
        <br />
        <span itemprop="telephone">+44 (0)1502 806990</span>
        <br />
        <span itemprop="telephone">+44 (0)7920 162548</span>
        <br />
        <br />
        <a href="mailto:info@marconltd.co.uk" itemprop="email">
          info@marconltd.co.uk
        </a>
        <br />
        <a itemprop="url" href="http://marconltd.co.uk">
          marconltd.co.uk
        </a>
      </address>
    </div>
  </Layout>
);

export default IndexPage;
